import Link from 'next/link';
// import Image from 'next/image';
import CImage from '@root/common/CImage';
import React, {useState} from 'react';
import {get, hasIn, isArray} from 'lodash';
import AddToCart from '@root/common/AddToCart';
import DeviceView from '@root/common/DeviceView';
import LocaleService from '@root/services/locale';

import Badges from './components/Badges';
import styles from './styles.module.scss';
import Pricing from './components/Pricing';
import VipStrip from './components/VipStrip';
import ConfigsMobile from './components/ConfigsMobile';
import ConfigsDesktop from './components/ConfigsDesktop';
import ExpressDelivery from '@root/common/ExpressDelivery';
import NextAvailDelService from '@root/services/nextAvailDelivery';

const defaultConfig = data => {
  return get(data, ['child', 0], data);
};

function ProductMiniature({data}) {
  const {t} = LocaleService.useLocale();
  const { locData } = NextAvailDelService.useLocationData();
  const [selected, setSelected] = useState(defaultConfig(data));

  const showExpress = () => {
    return locData?.regions?.express_delivery_available && data?.express_delivery
  }

  const hasConfigs = () => {
    return isArray(data.child) && data.child.length !== 0;
  };

  const badges = () => {
    return get(selected, ['additional_data', 'labels'], []);
  };

  const configs = () => {
    return get(data, ['child'], []);
  };

  const onSelectConfig = config => {
    setSelected(config);
  };

  const hasPercent = hasIn(data, ['percent_off']);
  const percentOff = get(data, ['percent_off']);

  return (
    <div className={styles.miniature}>
      <div className={styles.miniature_thumb}>
        <div className={styles.miniature_badges}>
          {showExpress() && <ExpressDelivery icon='truck-delivery' />}
          <Badges data={badges()} />
        </div>
        {hasPercent && (
          <span className={styles.miniature_percent}>{percentOff}</span>
        )}
        <Link
          href={{
            pathname: data?.url,
            query: hasConfigs() ? {config: selected.id} : null,
          }}>
          <div className={styles.miniature_image}>
            <CImage 
              width={420}
              height={420}
              alt="product"
              src={get(selected, ['image'])}
            />
            {/* <Image
              width={420}
              height={420}
              alt="product"
              src={get(selected, ['image'])}
            /> */}
          </div>
        </Link>
      </div>
      <div className={styles.miniature_addcart}>
        <AddToCart product={data} configOption={selected} />
      </div>
      <div className={styles.miniature_origin}>
        {get(selected, ['origin']) && (
          <span className={styles.miniature_origin_text}>
            {get(selected, ['origin'])}
          </span>
        )}
      </div>
      <div className={styles.miniature_sumary}>
        <div className={styles.miniature_name}>
          <span className={styles.miniature_name_text}>
            {get(selected, ['name'])}
          </span>
        </div>
        <div className={styles.miniature_price}>
          <Pricing data={selected} />
        </div>
        <div className={styles.miniature_configs}>
          <DeviceView
            mobile={
              <ConfigsMobile
                product={selected}
                configs={configs()}
                onSelect={onSelectConfig}
              />
            }
            desktop={
              <ConfigsDesktop
                product={selected}
                configs={configs()}
                onSelect={onSelectConfig}
              />
            }
          />
        </div>

        {get(data, ['vip_price']) && (
          <div className={styles.miniature_vip}>
            <VipStrip
              value={get(data, ['vip_price'])}
              percent={get(data, ['vip_percent_off'])}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductMiniature;
